footer {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 15px;
  line-height: 24px;
  color: #737373;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 800px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

footer hr {
  border-top-color: #bbb;
  opacity: 0.5
}

footer hr.small {
  margin: 20px 0;
}

footer h6 {
  padding-top: 30px;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 5px;
  letter-spacing: 2px;
}

footer a {
  color: #737373;
}

footer a:hover {
  color: #3366cc;
  text-decoration: none;
}

.underline-text {
  margin: 0;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

.social-icons {
  padding-left: 0;
  padding-bottom: 10px;
  margin-bottom: 0;
  margin-top: 7px;
  list-style: none
}

.social-icons li {
  display: inline-block;
  margin-bottom: 4px
}

.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px
}

.social-icons a {
  color: #818a91;
  font-size: 16px;
  display: inline-block;
  line-height: 36px;
  width: 36px;
  height: 36px;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  -webkit-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all .2s linear;
}

.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #fff;
  background-color: #29aafe
}

.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px;
}

.social-icons a.discord:hover {
  background-color: #5764f1;
}

.social-icons a.github:hover {
  background-color: #3b3b3b;
}

@media (max-width:767px) {
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600;
  }
}