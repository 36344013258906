.entity-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.content-body-root {
  justify-content: normal;
}